var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"booking-manual-request"},[_vm._m(0),(
      _vm.booking.booking_status === _vm.BookingStatus.Cancelled ||
      _vm.booking.booking_status === _vm.BookingStatus.Declined ||
      _vm.booking.booking_status === _vm.BookingStatus.Expired
    )?[_c('p',{staticClass:"booking-manual-request__title"},[_vm._v(" "+_vm._s(_vm.$t(("booking.status." + (_vm.booking.booking_status.toLowerCase()) + ".title")))+" ")]),_c('p',{staticClass:"booking-manual-request__description"},[_vm._v(" "+_vm._s(_vm.$t( ("booking.status." + (_vm.booking.booking_status.toLowerCase()) + ".description") ))+" ")])]:(!_vm.editable)?[(
        _vm.booking.booking_status !== _vm.BookingStatus.Declined &&
        _vm.booking.booking_status !== _vm.BookingStatus.Cancelled
      )?_c('p',{staticClass:"booking-manual-request__title"},[_vm._v(" "+_vm._s(_vm.$t('booking.manual.request.summary.title'))+" ")]):_vm._e(),(
        _vm.booking.booking_status !== _vm.BookingStatus.Declined &&
        _vm.booking.booking_status !== _vm.BookingStatus.Cancelled
      )?_c('p',{staticClass:"booking-manual-request__description"},[_vm._v(" "+_vm._s(_vm.$t('booking.manual.request.summary.description', { time: _vm.time }))+" ")]):_vm._e(),_c('p',{staticClass:"booking-manual-request__update-info",domProps:{"innerHTML":_vm._s(_vm.$t('booking.manual.request.summary.update'))}})]:[_c('p',{staticClass:"booking-manual-request__title"},[_vm._v(" "+_vm._s(_vm.$t('booking.editable.request.summary.title'))+" ")]),_c('p',{staticClass:"booking-manual-request__description"},[_vm._v(" "+_vm._s(_vm.$t('booking.editable.request.summary.description', { time: _vm.time }))+" ")])],_c('router-link',{attrs:{"to":_vm.useGetLocalizedPath(((_vm.BookingRoutes.BOOKINGS) + "/" + _vm.bookingId))}},[_c('WMButton',{staticClass:"booking-manual-request__details",attrs:{"straight":"","icon-class-name":"fa-solid fa-table fa-lg"}},[_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(_vm.$t('booking.manual.request.summary.actions.details')))])])],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"booking-manual-request__image"},[_c('img',{attrs:{"alt":"City background","src":"https://media.wemeet.nl/gallery/assets/common/city_bg.png?tr=w-413,h-311","loading":"lazy"}})])}]

export { render, staticRenderFns }