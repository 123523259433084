import { computed, defineComponent } from '@vue/composition-api';
import { BookingRoutes } from '@/Booking/booking.const';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props, _) {
        const bookingId = computed(() => props.booking.booking_id);
        return {
            BookingRoutes,
            bookingId,
            useGetLocalizedPath
        };
    }
});
