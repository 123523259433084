import { required, maxLength, requiredIf, minLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { BookingStatus } from '@/generated-types/graphql.types';
import { computed, defineComponent, reactive, watch } from '@vue/composition-api';
import { cleanObjectForGTM } from '@/util/gtm/gtmFunctions';
import { dateString } from '@/util/dates';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import ListItem from '@/uikit/ListItem.vue';
import Popover from '@/components/Popover.vue';
import { HelpdeskRoutes } from '@/Helpdesk/helpdesk.const';
export default defineComponent({
    components: {
        ListItem,
        Popover
    },
    mixins: [validationMixin],
    validations: {
        formData: {
            meeting: {
                required
            },
            description: {
                required: requiredIf(function () {
                    return (this.booking &&
                        this.booking.is_discount_applied &&
                        !this.booking.booking_meta.coupon);
                }),
                maxLength: maxLength(255)
            },
            isAfterpayRequested: {},
            organization: {
                organisation_name: {
                    maxLength: maxLength(50)
                },
                billing_address: {
                    maxLength: maxLength(100)
                },
                kvk_number: {
                    maxLength: maxLength(8),
                    minLength: minLength(8)
                },
                afterpay_description: {
                    maxLength: maxLength(1000)
                }
            },
            terms: {
                checked: value => value === true
            }
        }
    },
    props: {
        booking: {
            type: Object,
            default: null
        },
        user: {
            type: Object,
            default: null
        },
        afterpayDescriptionValidationError: {
            type: String,
            default: ''
        }
    },
    setup(props, context) {
        const { root, emit } = context;
        const paymentOptions = computed(() => {
            let defaultPaymentOptions = [
                {
                    label: root
                        .$t('booking.manual.request.form.payment_options.direct_payment')
                        .toString(),
                    value: false,
                    key: 'direct'
                }
            ];
            if (props.booking?.space?.is_afterpay_allowed) {
                if (props.booking?.space?.is_payment_options_selectable) {
                    defaultPaymentOptions.push({
                        label: root
                            .$t('booking.manual.request.form.payment_options.post_payment')
                            .toString(),
                        value: true,
                        key: 'afterpay'
                    });
                }
                else {
                    defaultPaymentOptions = [
                        {
                            label: root
                                .$t('booking.manual.request.form.payment_options.post_payment')
                                .toString(),
                            value: true,
                            key: 'afterpay'
                        }
                    ];
                }
            }
            return defaultPaymentOptions;
        });
        const paymentOptionsStatus = computed(() => {
            return !(props.booking?.origin &&
                props.booking.origin?.booking_status === BookingStatus.Paid);
        });
        watch(() => props.booking, () => {
            if (props.booking) {
                const isOnlyAfterpayAllowed = (props.booking?.space?.is_afterpay_allowed &&
                    !props.booking?.space?.is_payment_options_selectable) ||
                    false;
                formData.isAfterpayRequested = isOnlyAfterpayAllowed;
            }
        });
        const formData = reactive({
            meeting: props.booking?.meeting_name,
            description: props.booking?.meeting_description,
            isAfterpayRequested: !!(props.booking?.space?.is_afterpay_allowed &&
                !props.booking?.space?.is_payment_options_selectable),
            terms: false,
            organization: {
                organisation_name: '',
                billing_address: '',
                kvk_number: '',
                afterpay_description: ''
            }
        });
        watch(() => props.booking?.origin, booking => {
            if (booking) {
                formData.meeting = booking.meeting_name;
                formData.description = booking.meeting_description;
                formData.isAfterpayRequested = booking.is_afterpay_requested;
                if (booking.order.order_meta.organisation_name) {
                    formData.organization.organisation_name =
                        booking.order.order_meta.organisation_name;
                    formData.organization.billing_address =
                        booking.order.order_meta.billing_address;
                    formData.organization.kvk_number =
                        booking.order.order_meta.kvk_number;
                    formData.organization.afterpay_description =
                        booking.order.order_meta.afterpay_description || '';
                }
            }
        }, { immediate: true });
        const handleSubmit = (form) => {
            if (props.booking) {
                let analyticsData = cleanObjectForGTM(props.booking);
                analyticsData.booking_start = dateString(analyticsData.slot_start);
                analyticsData.booking_end = dateString(analyticsData.slot_start);
                analyticsData.is_afterpay_requested = form.$model.isAfterpayRequested;
                analyticsData.afterpay_description =
                    form.$model.organization.afterpay_description.$model;
                if (analyticsData.booking_type === 'DIRECT') {
                    // Send event
                    globalThis.$gtm.trackEvent({
                        event: 'direct_booking_summary',
                        category: 'bookings',
                        action: 'direct_booking_summary',
                        label: undefined,
                        value: props.booking?.booking_id,
                        noninteraction: false
                    });
                }
                else {
                    // Send event
                    globalThis.$gtm.trackEvent({
                        event: 'booking_request_summary',
                        category: 'bookings',
                        action: 'booking_request_summary',
                        label: undefined,
                        value: props.booking?.booking_id,
                        noninteraction: false
                    });
                }
                emit('submit', form.$model);
            }
        };
        return {
            formData,
            paymentOptions,
            paymentOptionsStatus,
            HelpdeskRoutes,
            handleSubmit,
            useGetLocalizedPath
        };
    }
});
