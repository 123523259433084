import { BookingRoutes } from '@/Booking/booking.const';
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import { BookingStatus } from '@/generated-types/graphql.types';
import { getRelativeTime } from '@/util/dates';
import { useGetLocalizedPath } from '@/util/globalHelpers';
export default defineComponent({
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        const { root } = context;
        const bookingId = computed(() => root.$route.params.bookingId);
        const time = computed(() => getRelativeTime(props.booking?.order?.ts_host_approval_dl));
        const editable = ref(false);
        onMounted(() => {
            if (props.booking.origin) {
                editable.value = true;
            }
        });
        return {
            bookingId,
            editable,
            time,
            BookingRoutes,
            BookingStatus,
            useGetLocalizedPath
        };
    }
});
