import { defineComponent, computed, ref, onMounted } from '@vue/composition-api';
import ListDropdown from '@/uikit/ListDropdown.vue';
import ListItem from '@/uikit/ListItem.vue';
import ShareToPay from '@/Booking/components/ShareToPay.vue';
import { useRequestPayTimeMutation, useSubmitFreeBookingMutation, BookingStatus } from '@/generated-types/graphql.types';
import { usePaymentLink } from '@/util/hooks';
import { ADD_TOAST_MESSAGE } from 'vuex-toast';
import { useGetLocalizedPath } from '@/util/globalHelpers';
import DeadlineCountdownTimer from '@/components/DeadlineCountdownTimer.vue';
import { BookingRoutes } from '@/Booking/booking.const';
export default defineComponent({
    components: {
        ListDropdown,
        ListItem,
        ShareToPay,
        DeadlineCountdownTimer
    },
    props: {
        booking: {
            type: Object,
            default: null
        }
    },
    setup(props, context) {
        const { emit, root } = context;
        const { pay, loading } = usePaymentLink(props.booking.booking_id, useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${props.booking.booking_id}`));
        const editable = ref(false);
        const submitFreeBookingButtonDisabled = ref(false);
        onMounted(() => {
            if (props.booking.origin) {
                editable.value = true;
            }
        });
        const { mutate: submitFreeBooking } = useSubmitFreeBookingMutation({});
        const submitBooking = () => {
            submitFreeBookingButtonDisabled.value = true;
            submitFreeBooking({ bookingId: props.booking.booking_id }, {}).then(
            // Dirty hack to wait for server db transaction to finish to get correct booking status
            setTimeout(() => {
                globalThis.$router.push(useGetLocalizedPath(`${BookingRoutes.BOOKINGS}/${props.booking.booking_id}`));
            }, 1000));
        };
        const { mutate: requestPaymentTime, onDone } = useRequestPayTimeMutation({});
        const requestTime = () => {
            requestPaymentTime({ id: props.booking.booking_id }, {}).then(() => emit('moreTimeToPayRequested'));
        };
        const canBookingBeExtended = computed(() => {
            return props.booking?.reservation_time >= 4320;
        });
        onDone(({ data }) => {
            // When useRequestPayTimeMutation has been executed successfully
            const isExtended = data?.update_booking?.is_reservation_extended;
            const addToast = globalThis.$store.dispatch.bind(null, ADD_TOAST_MESSAGE);
            if (isExtended) {
                addToast({
                    text: root.$i18n.t('booking.reservation_extended'),
                    type: 'success',
                    dismissAfter: 10000
                });
            }
        });
        return {
            canBookingBeExtended,
            editable,
            loading,
            submitFreeBookingButtonDisabled,
            BookingStatus,
            pay,
            requestTime,
            submitBooking
        };
    }
});
